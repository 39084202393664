import {
  ButtonPrimaryLink,
  EmphasizedText,
  TextToutFullwidth,
  TwoColumnContentNodeWrapper,
  TwoColumnsWithImage,
  device,
} from "@xolvio/xolvio-ui";
import React from "react";
import {
  MoveHack,
  ToutContent,
  ToutHeader,
  ToutTextBody,
  ToutWrapper,
} from "../../components/Services";
import { LayoutDefault } from "../../components/LayoutDefault";
import { TwoColumnSectionHeader } from "../../components/Services/ServicePageTemplate";
import { Text } from "../../components/HowWeWork";
import styled from "styled-components";
import { contactUsFooterProps } from "../../pages-content/homepage";
import { Subheader } from "../services/rapid-backend-development";

const CaseStudies = () => (
  <LayoutDefault>
    <>
      <ToutWrapper>
        <ToutContent>
          <ToutHeader>
            <span>Our work</span>
          </ToutHeader>
          <ToutTextBody style={{ textAlign: "center" }}>
            {"Case studies for high-complexity software development."}
          </ToutTextBody>
        </ToutContent>
      </ToutWrapper>

      <MoveHack top="-100px" topMobile={"100px"} />

      <TwoColumnsWithImage
        imagePosition={"right"}
        imageNode={
          <CaseStudyLogo
            src={"/assets/images/logos/studykik.png"}
            width="400px"
          />
        }
        contentNode={
          <TwoColumnContentNodeWrapper>
            <TwoColumnSectionHeader>
              <Subheader>StudyKIK</Subheader>
              <EmphasizedText
                text={"System modernization & API development"}
                highlight={"modernization"}
              />
            </TwoColumnSectionHeader>
            <Text>
              Complex monolithic system refactor to event-driven, distributed
              architecture in just three months. In parallel to the
              modernization, we designed & implemented an Apollo supergraph,
              enabling agile CX innovation.
            </Text>
            <ButtonPrimaryLink
              as={"a"}
              href={"/case-studies/studykik-case-study/"}
            >
              See case study
            </ButtonPrimaryLink>
          </TwoColumnContentNodeWrapper>
        }
      />

      <MoveHack top="-100px" topMobile={0} />

      <TwoColumnsWithImage
        height="300px"
        imageHeight="300px"
        imagePosition={"left"}
        imageNode={
          <CaseStudyLogo
            src={"/assets/images/logos/Audi_Logo.svg"}
            width="300px"
          />
        }
        contentNode={
          <TwoColumnContentNodeWrapper>
            <TwoColumnSectionHeader>
              <Subheader>Audi of America</Subheader>
              <EmphasizedText
                text={"e-tron reservations system"}
                highlight={"reservations"}
              />
            </TwoColumnSectionHeader>
            <Text>
              Highly complex, bespoke e-reservation platform capable of
              processing thousands of reservations per minute. The e-tron
              reservations system was developed in record time, yet it performed
              perfectly and at scale from the get-go.
            </Text>
            <ButtonPrimaryLink as={"a"} href={"/case-studies/audi-case-study/"}>
              See case study
            </ButtonPrimaryLink>
          </TwoColumnContentNodeWrapper>
        }
      />

      <MoveHack top="150px" topMobile={0} />

      <TwoColumnsWithImage
        imagePosition={"right"}
        height="250px"
        imageHeight="250px"
        imageNode={
          <CaseStudyLogo
            src={"/assets/images/logos/Volkswagen_logo.svg"}
            width="250px"
          />
        }
        contentNode={
          <TwoColumnContentNodeWrapper>
            <TwoColumnSectionHeader>
              <Subheader>Volkswagen of America</Subheader>
              <EmphasizedText
                text={"Car-Net infrastructure"}
                highlight={"infrastructure"}
              />
            </TwoColumnSectionHeader>
            <Text>
              Advanced cloud infrastructure to host the Car-Net platform, a
              connected car services suite. Our work empowered Volkswagen and
              their development partner to confidently deliver at lightning
              speed, while maintaining ultra high quality.
            </Text>
            <ButtonPrimaryLink
              as={"a"}
              href={"/case-studies/volkswagen-case-study/"}
            >
              See case study
            </ButtonPrimaryLink>
          </TwoColumnContentNodeWrapper>
        }
      />
      <MoveHack top="150px" topMobile={0} />

      <TextToutFullwidth {...contactUsFooterProps} />
    </>
  </LayoutDefault>
);

export default CaseStudies;

const CaseStudyLogo = styled.img`
  @media ${device.mobile} {
    display: none;
  }
  @media ${device.tabletVertical} {
    display: block;
  }
`;
